<template>
  <div
    v-on-click-outside="hideDropdown"
    class="relative"
  >
    <slot
      :toggle-dropdown="toggleDropdown"
      :is-dropdown-visible="isDropdownVisible"
    ></slot>

    <div
      v-if="isDropdownVisible"
      class="absolute right-0 top-[calc(100%+8px)] z-30 rounded-12 border border-e6e7ec/50 bg-white px-6 py-8 shadow-lg shadow-black/10"
    >
      <ul class="flex flex-col gap-8">
        <slot
          name="options"
          :hide-dropdown="hideDropdown"
        ></slot>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"

const isDropdownVisible = ref(false)

function toggleDropdown() {
  isDropdownVisible.value = !isDropdownVisible.value
}

function hideDropdown() {
  isDropdownVisible.value = false
}
</script>
